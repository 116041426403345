import React, { useState } from "react"
import styled from "styled-components"
import Lightbox from "react-image-lightbox"
import { Box } from "theme-ui"

import * as Scroll from "react-scroll"
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll"

import Layout from "../components/Layout"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"
import PrivateEventsBanner from "../components/PrivateEvents/PrivateEventsBanner"
import pdfMenu from "../assets/dockside-private-events.pdf"
import GrassyBanner from "../assets/private-events/grassy-1.jpg"
import PergolaBanner from "../assets/private-events/pergola-1.jpg"
import PrivateEventsContentContainer from "../components/PrivateEvents/PrivateEventsContentContainer"
import StyledForm from "../components/PrivateEvents/StyledForm"

import Grassy2 from "../assets/private-events/grassy-2.jpg"
import Grassy3 from "../assets/private-events/grassy-3.jpg"

import Pergola2 from "../assets/private-events/pergola-2.jpg"
import Pergola3 from "../assets/private-events/pergola-3.jpg"
import Pergola4 from "../assets/private-events/pergola-4.jpg"
import useStories from "../hooks/useStories"
import TextContent from "../components/TextContent"
import MultipleLinksSelector from "../components/Links/MultipleLinksSelector"
import cloudinaryString from "../helpers/cloudinaryString"
import EventsScript from "../components/EventsScript"

const PrivateEvents = () => {
  const [lightBox, setLightbox] = useState({
    isOpen: false,
    mainSrc: "",
  })
  const grassy4 =
    "https://res.cloudinary.com/gonation/image/upload/v1680555068/sites/dockside-brewing/image1_1.jpg"
  const pergola5 =
    "https://res.cloudinary.com/gonation/image/upload/v1680555079/sites/dockside-brewing/image3_1.jpg"
  const pergola6 =
    "https://res.cloudinary.com/gonation/image/upload/v1680555077/sites/dockside-brewing/image4_1.jpg"
  const pergola7 =
    "https://res.cloudinary.com/gonation/image/upload/v1680555074/sites/dockside-brewing/image2_1.jpg"

  const { getStoryByTag } = useStories()

  const privateEvent = getStoryByTag("private-events")
  const privateEvent1 = getStoryByTag("private-events1")
  const privateEvent2 = getStoryByTag("private-events2")
  const privateEvent3 = getStoryByTag("private-events3")
  const privateEvent4 = getStoryByTag("private-events4")
  const privateEvent5 = getStoryByTag("private-events5")
  const privateEvent6 = getStoryByTag("private-events6")
  const privateEvent7 = getStoryByTag("private-events7")
  const privateEvent8 = getStoryByTag("private-events8")
  const privateEvent9 = getStoryByTag("private-events9")
  const privateEvent10 = getStoryByTag("private-events10")
  const privateEvent11 = getStoryByTag("private-events11")
  const privateEvent12 = getStoryByTag("private-events12")

  const events = [
    privateEvent,
    privateEvent1,
    privateEvent2,
    privateEvent3,
    privateEvent4,
    privateEvent5,
    privateEvent6,
    privateEvent7,
    privateEvent8,
    privateEvent9,
    privateEvent10,
    privateEvent11,
    privateEvent12,
  ]

  return (
    <Layout pageTitle="private-events">
      <div>
        <Box>
          <EventsScript />
        </Box>
        <PrivateEventsBanner>
          <Box
            sx={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextContent
              title={privateEvent.title}
              subtitle={privateEvent.subtitle}
              body={privateEvent.body}
            />
            <MultipleLinksSelector links={privateEvent.ctaLinks} />
          </Box>
        </PrivateEventsBanner>
        {/* <ImageBanner
          src={cloudinaryString(privateEvent1?.media?.[0].cloudinaryId, 2000)}
        /> */}

        {/* <ImageBanner
          src={cloudinaryString(privateEvent3?.media?.[0].cloudinaryId, 2000)}
        /> */}
        <PrivateEventsContentContainer
          setLightbox={setLightbox}
          title={privateEvent4?.title}
          subtitle={privateEvent4?.subtitle}
          text={[privateEvent4?.body]}
          ctaLinks={privateEvent4?.ctaLinks}
          image={privateEvent4.media.map(
            ({ cloudinaryId }) => cloudinaryString(cloudinaryId, 1000) ?? ""
          )}
        />
        <PrivateEventsContentContainer
          setLightbox={setLightbox}
          title={privateEvent9?.title}
          subtitle={privateEvent9?.subtitle}
          text={[privateEvent4?.body]}
          ctaLinks={privateEvent9?.ctaLinks}
          image={privateEvent9.media.map(
            ({ cloudinaryId }) => cloudinaryString(cloudinaryId, 1000) ?? ""
          )}
        />

        {/* Add privateEvent10 */}
        <PrivateEventsContentContainer
          setLightbox={setLightbox}
          title={privateEvent10?.title}
          subtitle={privateEvent10?.subtitle}
          text={[privateEvent10?.body]}
          ctaLinks={privateEvent10?.ctaLinks}
          image={privateEvent10?.media?.map(
            ({ cloudinaryId }) => cloudinaryString(cloudinaryId, 1000) ?? ""
          )}
        />

        {/* Add privateEvent12 */}
        <PrivateEventsContentContainer
          setLightbox={setLightbox}
          title={privateEvent12?.title}
          subtitle={privateEvent12?.subtitle}
          text={[privateEvent12?.body]}
          ctaLinks={privateEvent12?.ctaLinks}
          image={privateEvent12?.media?.map(
            ({ cloudinaryId }) => cloudinaryString(cloudinaryId, 1000) ?? ""
          )}
        />

        {/* Add privateEvent11 */}
        <PrivateEventsContentContainer
          setLightbox={setLightbox}
          title={privateEvent11?.title}
          subtitle={privateEvent11?.subtitle}
          text={[privateEvent11?.body]}
          ctaLinks={privateEvent11?.ctaLinks}
          image={privateEvent11?.media?.map(
            ({ cloudinaryId }) => cloudinaryString(cloudinaryId, 1000) ?? ""
          )}
        />

        {/* <Box id="eventsform" sx={{ pt: "5rem", backgroundColor: "white" }}>
          <StyledForm></StyledForm>
        </Box> */}
      </div>
      {lightBox.isOpen && (
        <Lightbox
          mainSrc={lightBox.mainSrc}
          onCloseRequest={() => setLightbox({ isOpen: false })}
        ></Lightbox>
      )}
    </Layout>
  )
}

export default PrivateEvents

const ImageBanner = styled.div`
  background-image: url(${({ src }) => src});
  /* background-attachment: fixed; */
  height: 575px;
  width: 100%;
  background-position: center;
  background-size: cover;
`
